<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card title="Add outlet">

                <b-form-group>
                    <label>Name</label>
                    <b-form-input v-model="addObject.name"/>
                </b-form-group>

                <b-form-group>
                    <label>Owner Name</label>
                    <b-form-input v-model="addObject.owner_name"/>
                </b-form-group>

                <b-form-group>
                    <label>Contact number</label>
                    <b-form-input v-model="addObject.contact_number"/>
                </b-form-group>

                <b-form-group>
                    <label>TIN</label>
                    <b-form-input v-model="addObject.tin"/>
                </b-form-group>

                <b-form-group>
                    <label>Segment</label>
                    <v-select
                        v-model="addObject.segment_id"
                        :reduce="segment => segment.id"
                        label="name"
                        :options="outletSegments"
                    />
                </b-form-group>

                <b-row>
                    <b-col>
                        <b-form-group>
                            <label>Lat</label>
                            <b-form-input :value="addObject.geo_location[1]" v-on:input="updateMarkerLocationLat"/>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group>
                            <label>Lng</label>
                            <b-form-input :value="addObject.geo_location[0]" v-on:input="updateMarkerLocationLng"/>
                        </b-form-group>
                    </b-col>
                </b-row>

                <div id="map" style="width: 100%; height: 500px"></div>

                <hr/>
                <b-button variant="primary" @click="addOutlet">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add</span>
                </b-button>
            </b-card>
        </b-overlay>
    </div>
</template>
<script>

    import {BCard, BOverlay, BFormGroup, BFormInput, BButton, BRow, BCol} from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import mapboxgl from 'mapbox-gl'
    import 'mapbox-gl/dist/mapbox-gl.css'

    export default {
        components: {
            vSelect,
            BCard,
            BOverlay,
            BFormGroup,
            BFormInput,
            BButton,
            BRow,
            BCol
        },
        data() {
            return {
                dataLoaded: false,
                outletSegments: [],

                map: null,
                marker: null,

                addObject: {
                    name: '',
                    owner_name: '',
                    contact_number: '',
                    tin: '',
                    geo_location: [
                        this.$store.state.app.default_map_view.center[0],
                        this.$store.state.app.default_map_view.center[1]
                    ],
                    segment_id: ''
                }
            }
        },
        methods: {
            loadData() {
                this.dataLoaded = false
                const thisIns = this
                const segmentLoadPromise = this.$http.get('/api/management/v1/outlet_segment')
                segmentLoadPromise.then(function(response) {
                    thisIns.outletSegments = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                Promise.all([segmentLoadPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            addOutlet() {
                const thisIns = this
                const addPromise = this.$http.post('/api/management/v1/outlet', this.addObject)
                addPromise.then(function() {
                    thisIns.$printSuccess('Outlet added')
                    thisIns.$router.go(-1)
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })
            },
            attachMap() {
                mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_TOKEN

                this.map = new mapboxgl.Map({
                    container: 'map',
                    style: 'mapbox://styles/mapbox/streets-v11',
                    center: this.$store.state.app.default_map_view.center,
                    zoom: this.$store.state.app.default_map_view.zoom
                })

                this.marker = new mapboxgl.Marker({
                    draggable: true
                }).setLngLat(this.$store.state.app.default_map_view.center).addTo(this.map)

                this.marker.on('dragend', this.onMarkerDragEnd)
            },
            onMarkerDragEnd() {
                this.$set(this.addObject.geo_location, 0, this.marker.getLngLat().lng)
                this.$set(this.addObject.geo_location, 1, this.marker.getLngLat().lat)
            },
            updateMarkerLocationLat(val) {
                this.$set(this.addObject.geo_location, 1, val)
                this.marker.setLngLat(this.addObject.geo_location)
            },
            updateMarkerLocationLng(val) {
                this.$set(this.addObject.geo_location, 0, val)
                this.marker.setLngLat(this.addObject.geo_location)
            }
        },
        mounted() {
            this.attachMap()
            this.loadData()
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>